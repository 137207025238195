import React, { useEffect } from "react"

const DownloadQoqolo = () => {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = '/static/qoqolo-brochure.pdf';
        link.download = 'Qoqolo Brochure.pdf';
        link.click();
      }, []);
    
      return (
        <div className="text-center margin-auto">
        <p><img src={require("~images/products/qoqolo.png").default} alt="Qoqolo"/></p>
        <h4>You are downloading Qoqolo Brochure ...</h4>
      </div>
      );
}

export default DownloadQoqolo